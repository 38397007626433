@import "~antd/dist/antd.css";
@import "./styles/variable.scss";
html,
body {
    background-color: white;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--color-font);
}

.d-flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-right {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-evenly {
    justify-content: space-evenly;
}

.align-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}
.flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.link {
    text-decoration: underline;
}

.full-height {
    height: 100vh;
}
.w-100 {
    width: 100%;
}

.box-12-of-24 {
    width: 49%;
    margin-right: 1%;
}

.box-6-of-24 {
    width: 24%;
    margin-right: 1%;
}

.row {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

.ant-page-header {
    background-color: var(--color-primary-dark);
    .ant-btn {
        &:hover {
            color: #fff;
            border-color: #fff;
        }
    }
    .ant-page-header-heading-title {
        color: #fff;
    }
}

.ant-form {
    width: 100%;
}

.ant-table {
    background: unset !important;
    table {
        table-layout: fixed !important;
        width: 100% !important;
        margin: 0 auto;
        background: white;
        th {
            &:first-child {
                width: 65px;
            }
        }
    }
}

.ant-form-item {
    display: block;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    all: unset;
    content: "*";
}

// label {
//     &::after {
//         content: "* :";
//         font-size: 85%;
//     }
// }
.ant-input,
.ant-input-password {
    border-radius: 10px;
}

.async-select {
    >div {
        // height: 32px;
        min-height: 32px;
        border-radius: 8px;
    }
    span {
        width: 0px;
    }
    .css-6q0nyr-Svg {
        display: none;
    }
    &:focus {
        border: none;
    }
}

.css-yk16xz-control {
    &:focus {
        border: none;
    }
}

.ant-tabs {
    overflow: unset;
}

.ant-form-item-has-error {
    .async-select {
        border: 1px solid #ff4d4f;
        border-radius: 10px;
    }
}

.async-select-dropdown {
    padding-right: 10px;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 10px;
}

.btn {
    border-radius: 10px;
}

.ellipsify {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-layout-sider {
    height: 100vh;
    position: fixed;
    background-color: #fff;
    overflow-y: scroll;
    .brand-logo {
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid gray;
        margin: 0 7px;
        span {
            font-size: 400%;
            letter-spacing: 5px;
            color: #6b6b6b;
            border: 5px solid #6b6b6b;
            border-radius: 50%;
            padding: 10px;
        }
        img {
            height: 60%;
        }
    }
    .icon-wrapper {
        width: 16px;
        margin-right: 10px;
        font-size: 14px;
        -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        display: inline-block;
        vertical-align: sub;
    }
    .icon {
        height: 100%;
        width: 100%;
        // g {
        //     fill: #1890ff;
        // }
    }
    .ant-menu-item,
    .ant-menu-submenu {
        span {
            font-size: 105%;
        }
    }
    .ant-menu-item-active,
    .ant-menu-item-selected,
    .ant-menu-submenu-active,
    .ant-menu-submenu-selected {
        .icon {
            transition: all 0.3s;
            fill: #1890ff;
            g {
                path {
                    fill: unset !important;
                }
                fill: #1890ff;
            }
        }
    }
}

.ant-layout-header {
    background: #1890ff;
}

.header {
    .content {
        width: 100%;
        font-size: 100%;
        margin-left: 2%;
        .username {
            color: white;
            font-size: 145%;
            // font-style: italic;
            font-weight: 600;
            @media only screen and (max-width: 480px) {
                font-size: 120%;
            }
        }
    }
}

.ant-layout-footer {
    background-color: var(--color-primary-dark);
}

.content {
    font-size: 20px;
}

.edit-icon {
    color: var(--color-primary);
}

.delete-icon {
    color: red;
}

// .ant-select-item-option {
.select-option-img {
    height: 20px;
    width: 30px;
    object-fit: cover;
    border-radius: 3px;
    // position: absolute;
}

.select-option-img1 {
    border-radius: 8px;
    height: unset;
    width: 30px;
    object-fit: cover;
    position: absolute;
}

.dropdown-label {
    padding-left: 10px;
}

.cat-item-container {
    margin-bottom: 3px;
    background: #f5bf98;
    border-radius: 6px;
    .select-option-img1 {
        border-radius: 5px;
        width: 10%;
        object-fit: cover;
        // position: absolute;
        height: 1.5em;
    }
    .dropdown-label1 {
        padding-left: 5px;
    }
}

.sub-cat {
    margin-left: 10px;
    background: white;
    border-radius: 5px;
}

// }
.view-details-modal {
    .edit-icon {
        color: var(--color-primary);
    }
    .ant-modal-content {
        .large-img-wrap {
            height: 150px;
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            width: 40%;
            img {
                object-fit: cover;
                height: 100%;
                width: 150px;
                border-radius: 50%;
            }
            &.status-active {
                height: auto;
                display: block;
            }
        }
        .medium-img-wrap {
            height: 75px;
            margin-bottom: 10px;
            img {
                object-fit: cover;
                height: 100%;
                width: 75px;
                border-radius: 50%;
            }
        }
        .view-details {
            .personal-info {
                border-bottom: 1px solid #f0f0f0;
                padding-bottom: 25px;
                margin-bottom: 20px;
                h2 {
                    background-color: var(--color-primary-light);
                    color: white;
                    padding: 0 10px;
                    justify-content: left;
                }
                >div {
                    margin: 0 5%;
                }
                .personal-info-data {
                    width: 60%;
                    .label {
                        display: block;
                        float: left;
                        width: 100px;
                        text-align: left;
                        position: relative;
                        padding-right: 10px;
                        &:before {
                            content: ": ";
                            position: absolute;
                            right: 5px;
                        }
                        &:nth-child(2n+1) {
                            clear: both;
                        }
                    }
                    .value {
                        display: block;
                        margin-left: 100px;
                        word-break: break-all;
                        &:nth-child(2n+1) {
                            clear: both;
                        }
                    }
                    .job-change-value {
                        display: flex !important;
                        align-items: center;
                        // align-items: baseline;
                        .job-text {
                            display: flex;
                            margin-left: 5px;
                            // align-items: baseline;
                        }
                        @media only screen and (max-width: 468px) {
                            // align-items: baseline;
                        }
                    }
                }
                .detail-container {
                    @media only screen and (max-width: 730px) {
                        flex-direction: column-reverse;
                        .personal-info-data {
                            width: 100%;
                        }
                        .large-img-wrap {
                            width: 100%;
                        }
                    }
                }
            }
            .categories-info {
                padding-bottom: 25px;
                border-bottom: 1px solid #f0f0f0;
                h2 {
                    background-color: var(--color-primary-light);
                    color: white;
                    padding: 0 10px;
                    margin-bottom: 20px;
                    justify-content: left;
                }
                img {
                    border-radius: 8px;
                }
                >div {
                    margin: 0 5%;
                }
                .img-wrap {
                    height: 60px;
                    img {
                        height: 100%;
                    }
                }
                .category {
                    text-align: center;
                    margin-bottom: 10px;
                }
                .subcategory {
                    margin-bottom: 10px;
                    text-align: center;
                    // position: absolute;
                    // bottom: 0;
                }
                .sub-category-container {
                    width: 85%;
                    .sub-cat-item {
                        width: 29%;
                        margin-right: 20px;
                        .inner-div {
                            height: 100%;
                            position: relative;
                        }
                        @media only screen and (max-width: 468px) {
                            width: 40%;
                        }
                    }
                }
                .category-label {
                    display: block;
                    /* width: 100%; */
                    text-align: center;
                    // padding-right: 10px;
                }
                .main-category-data {
                    // border-right: 1px solid #a7a7a7;
                    margin-right: 20px;
                    width: 15%;
                    position: relative;
                    // .main-cat-item {
                    &:after {
                        content: " ";
                        margin-top: 1em;
                        height: calc(100% - 2em);
                        width: 1px;
                        background-color: #a7a7a7;
                        position: absolute;
                        right: -10px;
                    }
                    // }
                    @media only screen and (max-width: 468px) {
                        width: 20%;
                    }
                }
                .category-wrapper {
                    // border-bottom: 1px solid #a7a7a7;
                    // &:last-child {
                    //     border-bottom: none;
                    // }
                    margin-bottom: 20px;
                }
            }
        }
    }
    .ant-modal-footer {
        display: none;
    }
}

.job-toggler {
    margin: 0 15px;
}

.job-change-container {
    margin-top: 20px;
    // width: 170%;
    .review-container {
        position: relative;
        width: 100%;
        .review-title {
            font-weight: 600;
        }
        .review-rate {
            padding-top: 10px;
        }
        .review-err {
            position: absolute;
            font-size: 80%;
            color: red;
        }
    }
    .btn-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .review-submit-btn {
            margin-top: 30px;
            @media only screen and (min-width: 468px) {
                margin-right: 30px;
            }
        }
        .review-submit-btn-with-details {
            bottom: -80px;
        }
    }
    @media only screen and (min-width: 576px) {
        width: 125%;
    }
}

.category-view-details-modal {
    .ant-modal-header {
        background-color: var(--color-primary);
    }
    .detail-header {
        background-color: var(--color-primary);
        color: white;
        padding: 0 10px;
        font-size: 120%;
    }
    .delete-icon {
        margin-left: 10px;
    }
    .details {
        margin: 20px 40px;
        .large-img-wrap {
            height: 150px;
            img {
                object-fit: cover;
                height: 100%;
                width: 150px;
                border-radius: 8px;
            }
        }
        .right-details {
            margin-left: 20px;
        }
        .date {
            margin-bottom: 40px;
        }
        .label {
            font-weight: 600;
        }
        .item {
            margin-bottom: 10px;
        }
    }
    .ant-modal-footer {
        display: none;
    }
    // .indicato-container {
    .indicator {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-bottom: 5px;
    }
    .card-indicator {
        margin-bottom: 0;
        margin: 0 3px;
    }
    .grey {
        background: grey;
        border: 1px solid grey;
    }
    .green {
        background: rgb(4, 223, 4);
        border: 1px solidrgb(4, 223, 4);
    }
    .yellow {
        background: yellow;
        border: 1px solid yellow;
    }
    .red {
        background: red;
        border: 1px solid red;
    }
    // }
}

.ant-modal-header {
    background-color: var(--color-primary);
}

.detail-header {
    background-color: var(--color-primary);
    color: white;
    padding: 0 10px;
    font-size: 120%;
}

.ant-modal-close-x {
    color: white;
}


/*category rating css*/

.rating-info {
    .categories-info {
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .rating-detail {
        padding: 0px 40px;
    }
    .rating-title {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .rating {
        margin-bottom: 10px;
        .ant-rate-star {
            color: #fff;
            background-color: #e0e0e0;
            font-size: 14px;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 22px;
            border-radius: 3px;
            overflow: hidden;
            &.ant-rate-star-full {
                background-color: #FFAE00;
            }
            &.ant-rate-star-half {
                &.ant-rate-star-active {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        background-color: #FFAE00;
                        width: 50%;
                    }
                    > div {
                        width: 14px;
                        position: relative;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.rating-bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .rating-num {
        display: flex;
        align-items: center;
        & span+span {
            margin: 0 10px;
        }
    }
    .ant-progress-text {
        .anticon {
            svg {
                display: none;
            }
            &.anticon-check-circle:before {
                display: block;
                content: "100%";
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}

.heading {
    background-color: var(--color-primary-light);
    padding: 0 10px;
    color: white;
    justify-content: left;
    >* {
        color: white;
        justify-content: left;
    }
}
.highLightText{
    background-color:#fadb14;
}

.tab-heading {
    .ant-tabs-nav-wrap {
        background-color: var(--color-primary-light);
        padding: 0 10px;
        .ant-tabs-tab {
            padding: 7px 0;
            line-height: normal;
        }
        >* {
            color: white;
        }
        .ant-tabs-tab-btn {
            font-size: 1.2em;
            color: #fff;
        }
        .ant-tabs-ink-bar {
            background-color: #085298;
        }
    }
}

.evidence-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px !important;
    position: relative;
    .evidence-label {
        max-width: 150px;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        margin-right: 40px;
        padding-right: 20px;
        border-right: 1px solid #ddd;
    }
    > ul {
        padding: 0;
        margin: 0 -15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        li {
            list-style: none;
            padding: 0 15px;
        }
    }
    .evidence-box {
        font-weight: 500;
        margin-bottom: 10px;
        position: relative;
        .evidence-doc {
            border: 1px solid #ddd;
            width: 100px;
            height: 100px;
            font-size: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-bottom: 5px;
            position: relative;
            overflow: hidden;
            img {
                object-fit: cover;
                max-width: 100%;
                height: 100%;
            }
        }
        .remove-icon {
            top: -7px;
            position: absolute;
            right: -10px;
            background-color: #fff;
            border-radius: 100%;
            height: 20px;
            width: 20px;
            line-height: 20px;
            cursor: pointer;
            color: #ff0000;
            svg {
                font-size: 20px;
            }
        }
        .ant-upload-list-item {
            border: 0;
            padding: 0;
            .ant-upload-list-item-thumbnail {
                &[target="_blank"] {
                    pointer-events: none;
                }
            }
        }
        .ant-upload-list-item-info {
            &::before {
                content: normal;
            }
        }
        .ant-upload-list-item-actions {
            display: none;
        }
    }
    .add-icon {
        margin-left: auto;
        cursor: pointer;
        .anticon {
            margin-left: 20px;
            font-size: 24px;
        }
    }
    .textWrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .textFormat {
            display: flex;
            max-width: 100px;
            font-size: 12px;
            * {
                font-size: 14px;
                background-color: transparent !important;
                color: inherit !important;
                padding: 0 !important;
                + * {
                    margin-left: 5px;
                }
            }
            ol {
                padding: 0;
            }
            ul {
                padding: 0;
            }
            li {
                padding: 0;
            }
        }
    }
    .ant-tooltip {
        display: none;
    }
}

.preview-img {
    img {
        max-width: 100%;
    }
    .ant-upload-list-item-actions {
        button {
            display: none;
        }
    }
    .ant-upload-list-item-thumbnail {
        &a[target="_blank"] {
            pointer-events: all;
        }
    }
    .ant-upload {
        display: none;
    }
}
.upload-img-popup {
    .ant-tooltip {
        display: none;
    }
}
.apiLoader {
    position: fixed;
    right: 0;
    top: 0;
    left: 0px;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
    justify-content: center;
    z-index: 9999;
}
.apiLoader-review {
    position: relative;
    right: 0;
    top: 0;
    left: 0px;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
    justify-content: center;
    z-index: 9999;
}

.upload-btn {
    input {
        display: none
    }
    label {
        background-color: #1890ff;
        color: #fff;
        display: inline-block !important;
        padding: 5px 30px;
        cursor: pointer;
    }
}

.review {
    margin-bottom: 20px;
    border: 1px solid #6ec5ff;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    .reviewee-img {
        margin-right: 15px;
        img {
            max-width: 50px;
            height: 50px;
            width: 50px;
            border-radius: 100%;
        }
    }
    .reviewee-info {
        .reviewee-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            h6 {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 700;
            }
            .reviewee-rating {
                display: flex;
                align-items: center;
                >span {
                    margin: 0 5px;
                    font-weight: 700;
                    font-size: 16px;
                }
                .ant-rate {
                    font-size: 0;
                    .ant-rate-star {
                        font-size: 18px;
                        line-height: 18px;
                        vertical-align: top;
                    }
                }
            }
        }
        p {
            &:last-child {
                margin-bottom: 0
            }
        }
    }
    .reviewer-info {
        margin-left: auto;
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        .reviewer-title {
            h6 {
                font-size: 16px;
                margin-bottom: 0px;
            }
        }
        .reviewer-img {
            margin-left: 15px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
        }
    }
}
.file-upload {
    text-align: center;
    overflow: hidden;
    label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 20px;
    }
    input {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .audio-wrap {
        margin-top: 20px;
        display: inline-block;
        position: relative;
        background-color: #f1f3f4;
        vertical-align: top;
        .remove-icon {
            position: absolute;
            right: -10px;
            top: -10px;
            font-size: 20px;
            color: #ff0000;
            background-color: #fff;
            border-radius: 100%;
            height: 26px;
            width: 26px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .pdf-file-preview {
        position: relative;
        margin: 20px;
        .remove-icon {
            position: absolute;
            right: -8px;
            top: -8px;
            background-color: #fff;
            border-radius: 100%;
            height: 20px;
            cursor: pointer;
            width: 20px;
        }
    }
}
.ant-modal-body {
    min-height: 150px;
}

.upload-img-popup {
    .ant-upload-list-picture-card {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        .ant-upload-list-picture-card-container {
            margin: 15px auto 0;
            width: auto;
            height: auto;
            .ant-upload-list-item {
                border-radius: 0 !important;
                border-color: #ddd;
            }
        }
        .ant-upload-list-item-info {
            border-radius: 0 !important;
            &::before {
                left: 0;
                top: 0;
            }
        }
        .ant-upload-list-item-thumbnail {
            img {
                width: 300px;
                height: 150px;
                object-fit: cover;
            }
        }
    }
    .ant-upload {
        &.ant-upload-select-picture-card {
            width: auto;
            height: auto;
            border: 0;
            margin: 0;background-color: #1890ff;
            display: inline-block !important;
            padding: 5px 30px;
            cursor: pointer;
            border-radius: 0 !important;
            
            .ant-upload {
                span {
                    display: none;
                }
                div {
                    color: #fff !important;
                    font-size: 20px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    div {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
}

.custom-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    table {
        width: 100%;
        min-width: 700px;
        tr {
            &.sub-point {
                td {
                    &:first-child {
                        padding-left: 30px
                    }
                }
            }
            th {
                padding: 5px 10px;
            }
            td {
                padding: 5px 10px;
                white-space: nowrap;
                .topic_file {
                    float: left;
                    width: 50%;
                    span {
                        &:last-child {
                        margin-left: 5px;
                        width: 90px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        vertical-align: middle;
                        }
                    }
                    &:first-child {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

.curriculum_chapter {
    font-size: 16px;
    font-weight: 600;
}

.training-wrap {
    width: 100%;
}
.datePicker{
    width:100%;
}
.curriculum_topic {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-bottom: 5px;
    .curriculum_icon {
        a {
            margin-left: 10px;
            svg {
                fill: #ff0000;
                font-size: 18px;
            }
        }
    }
}
.trainingModuleFontSize{
    font-size: 85%; 
}
.trainingBottom{
    margin-left: 20.5%;
}
.trainingCurriculum{
    margin-top: 0.60%;
    margin-bottom: -0.5%;
}
.small-img {
    display: flex;
    white-space: nowrap;
    align-items: center;
    img {
        margin-right: 10px;
        height: 100%;
        border-radius: 50%;
        width: 50px;
    }
}
.table-wrap {
    margin-top: 20px;
    .ant-table {
        table {
            width: 100% !important;
        }
    }
}
.table-responsive {
    display: block;
    overflow: hidden;
    width: 100%;
    .ant-table-content {
        overflow: auto;
    }
    table {
        table-layout: auto !important;
    }
}
.list-card-view {
    margin-bottom: 20px;
}
.customer-details {
    display: flex;
    .label {
        width: 120px;
        position: relative;
        padding-right: 10px;
        :after {
            content: ": ";
            position: absolute;
            right: 5px;
            top: 0;
         }
    }
    .large-img-wrap {
        height: 60px !important;
        margin-left: auto;
        margin-bottom: 10px;
        img {
            width: 60px !important;
            border-radius: 0 !important;
        }
    }
}

.device-list {
    display: flex;
    justify-content: space-between;
}

.select-box {    
    .ant-select {
        float: right;
    }
}
.review-table {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    > p {
        text-align: center;
    }
}
.review-list {
    margin-bottom: 20px;
}
.category_info .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.view-details-modal .ant-form-item {
    margin-bottom: 10px
}

.status-wrap {
    margin-top: 10px;
    width: 100%;
    display: inline-block;
    .status-inner {
        .status-change-value {
            display: flex;
            margin-bottom: 10px;
        }
    }
    @media only screen and (max-width: 730px) {
        margin-left: -5px;
        margin-right: -5px;
        width: auto;
        display: table;
    }
}
@media only screen and (max-width: 1200px) {
    .row {
        max-width: 992px;
    }
}

@media only screen and (max-width: 992px) {
    html,
    body {
        font-size: 12px;
    }
    .row {
        max-width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .evidence-info {
        display: block;
        .evidence-label {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
            padding-right: 0; 
            border-right: 0;
            border-bottom: 1px solid #ddd;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }
        .add-icon {
            position: absolute;
            top: 7px;
            right: 0;
        }
    }
    .review {
        display: block;
        .reviewee-img {
            float: left;
        }
        .reviewee-info {
            overflow: hidden;
        }
        .reviewer-info {
            width: 100%;
        }
    }
    .rating-info {
        .rating-detail {
            padding: 0 20px;
        }
    }
}

@media only screen and (max-width: 575px) {
.category-view-details-modal {
    .details {
            .date {
                margin-bottom: 10px;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
.customer-details {
    .d-flex {
        display: block;
    }
}
.review {
    .reviewee-info {
        .reviewee-title {
            .reviewee-rating {
                .ant-rate {
                    .ant-rate-star {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
}